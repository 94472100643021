import React from 'react';
import DefaultLayout from '../components/layouts/DefaultLayout';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PageTitle } from '../components/page/PageTitle';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const FAQSPage = () => {
  return (
    <DefaultLayout>
      <PageTitle title={"FAQs"}/>
      <main className="breakaway-content">
        <Container>
          <StaticQuery
            query={/* language=GrapGQL */ graphql`
              query {
                Faq: file(relativePath: { eq: "sunrise.jpg" }) {
                  childImageSharp {
                  fluid(
                  maxWidth: 1100,
                  srcSetBreakpoints: [500, 700, 1020, 1200]
                  quality: 90
                  sizes: "(max-width: 4000px) 100vw, 2000px, (max-width: 1200px) 1200px, (max-width: 700px) 100vw, 700px, (max-width: 500px) 100vw, 500px"
                  ) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
               }
            `}
            render={data => (  <div><Img className="breakaway-page-image" fluid={data.Faq.childImageSharp.fluid}/></div>  )}
          />
          <Row>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <h4>What type of breaks are discounted with Breakaway Club?</h4>
              <p>We host a variety of breaks at Potters Resorts suited for any party type, from Family Breaks to Weekends With Friends and Midweek Leisure Breaks. Any one of them may be offered to Breakaway Club members at an exclusive price; this depends purely on whether we have any late availability.</p>
              <h4>How do I join?</h4>
              <p>Our Breakaway Club membership is limited and only available to guests who have enjoyed regular visits to Potters Resorts in recent years. As such, Breakaway Club membership is by invitation only. Our friendly team are happy to discuss your eligibility for Breakaway Club membership on <a href="tel:+443333207466">0333 3207 466</a>.</p>
              <h4>How much does it cost to join?</h4>
              <p>Current membership runs from 1st January 2020 through to 31st December 2023 (normally, membership lasts for 3 years, but we have extended this membership period for one year, free of charge) and costs £60 per household (up to 4 adults). Membership also includes any children under the age of 16 at that household.</p>
              <h4>How often are deals sent out?</h4>
              <p>The availability of breaks can change at late notice so we don’t have a schedule as such, but typically we’ll get in touch with you on a weekly to fortnightly basis. This will be with an update on the latest deals, any changes to availability or a friendly reminder of the deals already available to you.</p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <h4>Should I wait for Breakaway Club offers?</h4>
              <p>The nature of late availability means we cannot guarantee any particular break will be released with a Breakaway Club discount. We equally cannot guarantee preferred room types or that special requests can be met. We would always recommend that where you have a specific break in mind, to book as early as possible. However, it’s worth noting that Breakaway Club discounts cannot be applied to an existing booking.</p>
              <h4>Can I bring my friends and family?</h4>
              <p>Some Breakaway Club offers are limited to members only. However, where we have increased late availability, we may open the offer up to your friends and family too. Please just note that bookings can only be made by Breakaway Club members, who must be joining their friends and family on the chosen break.</p>
              <h4>What happens when my membership runs out?</h4>
              <p>Don’t worry, we’ll get in touch with you towards the end of 2023 with details about the renewal of your membership.</p>
              <h4>How many Breakaway Club offers can I book?</h4>
              <p>Once a member, you can take advantage of as many Breakaway Club offers as you like. Just note that Breakaway Club offers are subject to availability and once deals are announced, they often sell very quickly.
              </p>
            </Col>
            <Col xs={12} sm={12} md={6} lg={4} xl={4}>
              <h4>How does the ‘Day Before’ Benefit work?</h4>
              <p>If you book a break within 24 hours of it starting, you can pay a set price of just £75 per person, per night. This price applies to children as well, unless under 2, in which case they will be free. This benefit does not apply to breaks already advertised at Breakaway Club prices. In all circumstances, offers are subject to promotional rate availability so again, we would always recommend early booking where you have a specific break in mind.</p>
              <h4>What if I’m not receiving my Breakaway Club emails?</h4>
              <p>Emails are not sent on a set schedule, but if you haven’t heard from us in a couple of weeks, that may be a little peculiar. Firstly, check your spam or junk folders and if the email appears in there, please make sure you mark the email as safe. You can also add the email address <strong>breakaway@pottersholidays.com</strong> to your contacts/safe list so your inbox recognises our email address when we send your Breakaway Club offers. If you’ve taken these steps and are still not receiving emails from Breakaway Club, please get in touch with the team on <a href="tel:+443333207466">0333 3207 466</a> who will investigate further.</p>
              <p>If you have a different question regarding our Breakaway Club, you can contact our friendly team on <a href="tel:+443333207466">0333 3207 466</a>. We are open seven days a week; 9am to 9pm Monday to Friday and 9am to 6pm on Saturday & Sunday.</p>

              <p>Alternatively, you can email us at <a href="mailto:breakaway@pottersholidays.com">breakaway@pottersholidays.com</a> and we will get back to you as soon as we can.</p>
            </Col>
          </Row>
          <div className="text-center">
            <p className="lead">Call our friendly Reservations team today on <a href="tel:+443333207466">0333 3207 466</a>, or visit <a href="https://www.pottersholidays.com" target="_blank" rel="noreferrer noopener">www.pottersholidays.com</a></p>
          </div>
        </Container>
      </main>
    </DefaultLayout>
  );
};

export default FAQSPage;
